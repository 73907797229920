.footer {
    color: rgb(102, 105, 111);
    border-radius: .6em;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 1em;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    text-align: center;
    opacity: .9;
    transition: .3s;
}

footer:hover {
    opacity: 1;
}

.list {
    margin: 0;
    padding: 0;
    padding-top: .4em;
}

.list > li {
    display: inline;
    padding-left: .4em;
    padding-right: .4em;
    border-left: 1px solid rgba(133, 133, 133, .4);
}

.list > li:first-child {
    border-left: 0px;
}

.a {
    color: rgb(137, 142, 154);
    text-decoration: none;
}

.a:hover {
    color: rgb(161, 167, 180);
    text-decoration: underline;
}