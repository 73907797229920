.article {
    --text-color: rgb(39, 39, 39);
    --article-background-color: white;
    --code-text-color: rgb(49, 50, 51);
    --code-background-color: rgb(234, 242, 246);
    --code-border-color: rgb(212, 216, 219);
    --title-text-color: rgb(20, 97, 197);
    --link-text-color: rgb(40, 141, 204);
    --link-hover-color: rgb(78, 168, 225);
    --link-active-color: rgb(192, 241, 255);
    --link-visited-color: rgb(117, 93, 222);
    --bq-highlight: rgb(0, 110, 255);
    --bq-background: rgb(249, 253, 255);
    --font-size: 15px;
    color: var(--text-color);
    margin: 0 auto;
    margin-top: 1em;
    margin-bottom: 1em;
    max-width: 1000px;
    box-shadow: 0px 0px 10px -6px #000000dd, 0px 0px 3px -1px black;
    border-radius: .5em;
    padding: 1em;
    background-color: var(--article-background-color);
    /* animation-name: appear;
    animation-duration: .4s;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1); */
}

@media (prefers-color-scheme: dark) {
    .article {
        --text-color: rgb(228, 228, 228);
        --article-background-color: rgb(20, 20, 20);
        --code-text-color: rgb(202, 202, 202);
        --code-background-color: rgb(63, 63, 63);
        --code-border-color: rgb(77, 77, 77);
        --title-text-color: rgb(57, 139, 246);
        --link-text-color: rgb(145, 206, 244);
        --link-hover-color: rgb(78, 168, 225);
        --link-active-color: rgb(10, 59, 73);
        --link-visited-color: rgb(165, 149, 238);
        --bq-highlight: rgb(38, 83, 143);
        --bq-background: rgb(0, 0, 0);
    }
}