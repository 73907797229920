.container {
  width: 100%;
  height: 700px;
  box-shadow: 0px 0px 10px -6px #000000dd, 0px 0px 3px -1px black;
  border-radius: .5em;
  background-color: white;
  z-index: 100;
  position: relative;
  user-select: none;
  overflow: clip;
}

.container2 {
  height: 100%;
  width: 100%;
  cursor: move;
}

input.range {
  background-color: #00000000;
  width: 100%;
}

div.settings {
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  padding: .4em;
  position: absolute;
  width: 200px;
  max-width: 60%;
  margin: 3px;
  right: 6px;
  top: 6px;
  border-radius: 6px;
  z-index: 106;
  background-color: rgb(36, 36, 36);
  border: 1px solid black;
  box-shadow: 0px 0px 2px 0px black;
  color: white;
}

div.settings>button {
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  color: white;
  display: block;
  width: 100%;
  margin-right: .4em;
  margin-bottom: .4em;
  padding: .7em;
  padding-left: .7em;
  padding-right: .7em;
  border-radius: .5em;
  border: 0px solid rgb(0, 42, 84);
  box-shadow: 0px 0px 3px -1px rgb(0, 42, 84);
  cursor: pointer;
  transition: .04s;
  background-color: rgb(0, 93, 186);
  white-space: nowrap;
}

div.settings>button:hover {
  background-color: rgb(50, 149, 248);
}

div.settings>button:active {
  background-color: rgb(3, 70, 137);
  box-shadow: inset 0px 0px 6px -2px rgb(28, 28, 28);
}

div.settings>div {
  background-color: rgb(50, 50, 50);
  padding: .4em;
  margin-bottom: .4em;
  border-radius: .4em;
  color: white;
}

div.settings>label {
  display: block;
  background-color: rgb(50, 50, 50);
  color: white;
  padding: .4em;
  border-radius: .4em;
  margin-bottom: .4em;
}

div.settings> :last-child {
  margin-bottom: 0;
}

div.settings>div>a {
  color: rgb(211, 233, 255);
}

div.settings>div>a:hover {
  color: rgb(255, 255, 255);
}

div.settings>div>a:active {
  color: rgb(255, 255, 255);
  background-color: #2B61CC;
}

div.sh {
  cursor: pointer;
  background-color: rgb(80, 80, 80) !important;
  border-width: 0px !important;
  padding: 10px !important;
  transition: .1s;
}

div.sh:hover {
  background-color: rgb(102, 102, 102) !important;
}

div.sh:active {
  background-color: black !important;
}

.node {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  box-shadow: 0px 0px 7px -3px black, 0px 0px 3px -2px black, inset 0px 0px 0px 3px var(--type-color);
  z-index: 102;
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 100px;
  user-select: none;
  cursor: default;
  font-family: 'Inconsolata', monospace;
  font-size: 14px;
  line-height: 20px;
}

.node:hover {
  z-index: 103;
}

.nodedata {
  --nodeID: "ID";
  display: none;
  position: absolute;
  top: -1px;
  left: -1px;
  min-width: 160px;
  box-shadow: 0px 0px 7px -3px rgb(0, 0, 0), 0px 0px 3px -2px black;
  border-radius: .2em;
  border-top-left-radius: 25px;
  padding: 14px;
  background-color: #333;
  z-index: 103;
  align-items: flex-start;
  flex-direction: column;
  user-select: none;
  animation-name: appear;
  animation-duration: .25s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.nodedata::before {
  content: var(--nodeID);
  text-align: center;
  text-anchor: middle;
  width: 50px;
  height: 50px;
  top: 0px;
  left: 0px;
  background-color: rgb(255, 255, 255);
  box-shadow: inset 0px 0px 0px 3px var(--type-color);
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(.95);
  }

  100% {
    opacity: 1;
  }
}

.nodeTitle {
  margin-left: 45px;
  font-weight: 800;
}

.nodedata>* {
  color: #eee;
  user-select: none;
}

.node:hover>.nodedata {
  display: block;
}

p.nData {
  margin-top: 20px;
}

.link {
  height: 0;
  transform-origin: 0;
  outline: 1px solid black;
  z-index: 101;
  position: absolute;
  user-select: none;
  width: 100px;
  top: 100px;
  left: 200px;
  transform: rotate(-30deg);
}

.root {
  --type-color: rgb(145, 24, 24);
}

.Title {
  --type-color: rgb(0, 60, 255);
}

.Paragraph {
  --type-color: rgb(255, 0, 195);
}

.UnorderedList {
  --type-color: orange;
}

.OrderedList {
  --type-color: rgb(255, 80, 49);
}

.listItem {
  --type-color: rgb(142, 236, 102);
}

.Codeblock {
  --type-color: rgb(59, 59, 59);
}

.Image {
  --type-color: green;
}

.H2 {
  --type-color: rgb(69, 128, 239);
}

.H3 {
  --type-color: rgb(88, 88, 213);
}

.H4 {
  --type-color: rgb(88, 88, 213);
}

.Blockquote {
  --type-color: purple;
}

.Hr {
  --type-color: rgb(26, 163, 165);
}

.IlSmall {
  --type-color: lightgrey;
}

.IlCode {
  --type-color: rgb(87, 87, 87);
}

.IlItalic {
  --type-color: rgb(255, 231, 176);
}

.IlBold {
  --type-color: rgb(255, 207, 201);
}

.IlLink {
  --type-color: rgb(118, 201, 253);
}

.IlImage {
  --type-color: rgb(20, 128, 20);
}

.IlMark {
  --type-color: rgb(255, 230, 91);
}

.IlSubscript {
  --type-color: rgb(229, 144, 255);
}

.IlSuperscript {
  --type-color: rgb(181, 156, 255);
}

.IlStrikethrough {
  --type-color: rgb(227, 149, 255);
}

.IlText {
  --type-color: rgb(183, 155, 131);
}

.IlFootnote {
  --type-color: #672129;
}

.Footnote {
  --type-color: #c7606f;
}

.FootnoteContent {
  --type-color: #ee2233;
}


@media (prefers-color-scheme: dark) {
  .container {
    background-color: rgb(0, 0, 0);
  }
  .link {
    outline: 1px solid rgb(203, 203, 203);
  }
  .node {
    background-color: rgb(23, 23, 23);
    color: rgb(249, 249, 249);
  }
  .nodedata::before {
    background-color: rgb(23, 23, 23);
  }
}

/* generated with: https://www.cssportal.com/style-input-range/ */

input[type=range] {
  height: 23px;
  -webkit-appearance: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000;
  background: #2B61CC;
  border-radius: 3px;
  border: 0px solid #000000;
}

input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 1px #000000;
  border: 1px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #FFFFFF;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #2B61CC;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000;
  background: #2B61CC;
  border-radius: 3px;
  border: 0px solid #000000;
}

input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 1px #000000;
  border: 1px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #FFFFFF;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #2B61CC;
  border: 0px solid #000000;
  border-radius: 6px;
  box-shadow: 0px 0px 0px #000000;
}

input[type=range]::-ms-fill-upper {
  background: #2B61CC;
  border: 0px solid #000000;
  border-radius: 6px;
  box-shadow: 0px 0px 0px #000000;
}

input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 1px #000000;
  border: 1px solid #000000;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #FFFFFF;
  cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: #2B61CC;
}

input[type=range]:focus::-ms-fill-upper {
  background: #2B61CC;
}