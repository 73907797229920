.p {
    color: var(--text-color);
    margin-bottom: .3rem;
    margin-top: .3rem;
    font-size: var(--font-size);
    font-family: 'STIX Two Text', serif;
    line-height: calc(var(--font-size) * 1.4);
}

div.code {
    color: var(--code-text-color);
    background-color: var(--code-background-color);
    border: 1px solid var(--code-border-color);
    border-radius: 7px;
    padding: .2rem;
    line-height: calc(var(--font-size) * 1.4);
    overflow-x: auto;
}

div.code > code > pre {
    color: var(--code-text-color);
    font-size: var(--font-size);
    font-family: 'Inconsolata', monospace;
}

code.code {
    color: var(--code-text-color);
    background-color: var(--code-background-color);
    border: 1px solid var(--code-border-color);
    font-family: 'Inconsolata', monospace;
    border-radius: 7px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 1px;
}

.ol {
    margin-top: .4rem;
    margin-bottom: .4rem;
    font-size: var(--font-size);
    padding-left: 1.5rem;
}

.ul {
    margin-top: .4rem;
    margin-bottom: .4rem;
    font-size: var(--font-size);
    padding-left: 1.5rem;
}

.li {
    line-height: calc(var(--font-size) * 1.55);
    font-family: 'STIX Two Text', serif;
}

.small {
    opacity: .7;
    font-family: 'STIX Two Text', serif;
}

.h1 {
    color: var(--title-text-color);
    font-size: calc(var(--font-size) * 2.2);
    font-family: 'STIX Two Text', serif;
    font-weight: 600;
}

.h2 {
    font-size: calc(var(--font-size) * 1.6);
    font-family: 'STIX Two Text', serif;
    padding-top: 4px;
}

.h3 {
    font-size: calc(var(--font-size) * 1.3);
    font-family: 'STIX Two Text', serif;
    padding-top: 2px;
    padding-bottom: 2px;
}

.h4 {
    font-size: calc(var(--font-size) * 1.1);
    font-family: 'STIX Two Text', serif;
    padding-top: 2px;
    padding-bottom: 2px;
}

.a {
    color: var(--link-text-color);
}

.a:hover {
    color: var(--link-hover-color);
    text-decoration: none;
}

.a:active {
    color: var(--link-hover-color);
    text-decoration: none;
    background-color: var(--link-active-color);
}

.a:visited {
    color: var(--link-visited-color);
}

.img {
    max-height: 300px;
    display: block;
    margin: 0 auto;
    margin-bottom: 1em;
    margin-top: 1em;
    max-width: 70%;
}

.IlImage {
    display: inline;
}

.bq {
    border-left: 3px solid var(--bq-highlight);
    box-shadow: 0px 0px 6px -4px black, 0px 0px 3px -1px black;
    border-radius: 8px;
    margin: .4em;
    margin-left: .3em;
    padding: .4em;
    background-color: var(--bq-background);
}

.hr {
    border-width: 0px;
    border-bottom: 1px solid grey;
    width: 100%;
    margin: 0 auto;
    margin-top: 11px;
    margin-bottom: 12px;
}

.footnoteList {
    border-top: 1px solid gray;
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: calc(var(--font-size) * 0.92);
    font-family: 'STIX Two Text', serif;
    padding-left: 2em;
    padding-top: .4em;
}
  
.footnoteItem {
  padding-top: .5em;
  padding-bottom: .3em;
  color: #555;
}
  
.highlightFootnote {
    background-color: rgba(127, 200, 255, 0.119);
}
  