.nav {
  background-color: rgb(0, 0, 0);
  --navbar-thickness: 2.8em;
  --menubar-padding: 1.2em;
  --text-1: #ffffff;
  --text-2: rgb(163, 204, 255);
  --color-1: rgb(0, 110, 255);
  --color-2: rgb(0, 82, 189);
  --color-3: rgb(0, 50, 115);
  --bg-1: rgb(39, 39, 39, 0);
  box-shadow: 0px 0px 10px -3px #000000dd, 0px 0px 3px -1px black;
  user-select: none;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
}

.nav>ul {
  list-style: none;
  padding: 5px;
  padding-left: 1em;
}

.nav>ul>li {
  display: inline-block;
  height: 100%;
}

.nav>ul>li>a {
  display: inline-block;
  color: var(--text-1);
  height: 100%;
  background-color: var(--bg-1);
  border-radius: 2em;
  text-decoration: none;
  line-height: var(--navbar-thickness);
  padding-left: var(--menubar-padding);
  padding-right: var(--menubar-padding);
  transition: .1s;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
}

.nav>ul>li>a:hover {
  background-color: var(--color-1);
}

.nav>ul>li>a:active {
  background-color: var(--color-2);
  box-shadow: inset 0px 0px 10px -2px black, inset 0px 0px 3px 0px black;
  transform: scale(0.98);
}

div.head {
  background: linear-gradient(90deg, rgb(0, 58, 134), rgb(52, 140, 255));
  --text-1: #ffffff;
  --text-2: rgb(163, 204, 255);
  user-select: none;
}

div.head>a {
  text-decoration: none;
}

div.logo {
  max-width: 10em;
  height: 3.6em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

div.logo> :first-child {
  color: var(--text-1);
  /* margin-right: .2em; */
  font-size: 1.6em;
  font-weight: 600;
  font-family: 'Red Hat Display', sans-serif;
}

div.logo> :last-child {
  color: var(--text-2);
  font-size: 1.6em;
  font-weight: 400;
  font-family: 'Red Hat Display', sans-serif;
}

div.menuBox {
  position: relative;
  white-space: nowrap;
}

div.menuBox>span {
  display: inline-block;
  color: var(--text-1);
  height: 100%;
  background-color: var(--bg-1);
  border-radius: 2em;
  text-decoration: none;
  line-height: var(--navbar-thickness);
  padding-left: var(--menubar-padding);
  padding-right: var(--menubar-padding);
  /* transition: .1s; */
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  cursor: default;
}

div.menuBox:hover>ul {
  display: block;
}

div.menuBox:hover>span {
  background-color: var(--color-1);
  border-radius: 0;
  border-top-left-radius: .5em;
  border-top-right-radius: .5em;
}

ul.menuBox {
  --padding: .8em;
  --list-margin: .6em;
  --border-radius: .3em;
  display: none;
  position: absolute;
  background-color: rgb(36, 36, 36);
  border-top: 3px solid var(--color-1);
  list-style: none;
  margin: 0;
  padding: .5em;
  min-width: 150px;
  left: 0px;
  max-width: 80vw;
  min-width: 260px;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  animation-name: menuAppear;
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  border-radius: .6em;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0px 3px 10px -6px black, 0px 2px 4px -1px black;
  z-index: 200;
}

.tmpStyle {
  visibility: hidden !important;
  display: block !important;
}

@keyframes menuAppear {
  0% {
    opacity: 0;
    transform: scaleY(.8) translateY(-14%);
    display: block;
  }

  100% {
    opacity: 1;
    transform: scaleY(1) translateY(0%);
  }
}

ul.menuBox>li {
  margin-bottom: var(--list-margin);
}

ul.menuBox>li:last-child {
  margin-bottom: 0;
}

ul.menuBox>li>a {
  display: block;
  padding: var(--padding);
  background-color: #ffffff11;
  padding-left: 1.3em;
  color: white;
  border-radius: var(--border-radius);
  text-decoration: none;
  transition: .04s;
}

ul.menuBox>li>a:hover {
  background-color: var(--color-1);
}

ul.menuBox>li>a:active {
  background-color: var(--color-2);
  /* box-shadow: inset 0px 0px 10px -2px black, inset 0px 0px 3px 0px black; */
  transform: scale(0.98);
}

div.subMenu {
  border-radius: var(--border-radius);
  /* background-color: rgb(50, 50, 50); */
  background-color: rgba(100, 100, 100, .2);
  border-left: 2px solid white;
}

div.subMenu>span {
  box-sizing: border-box;
  padding: var(--padding);
  display: block;
  cursor: pointer;
  width: 100%;
  background-color: rgb(80, 80, 80);
  color: white;
  border-radius: var(--border-radius);
  transition: .04s;
}

div.subMenu>span:hover {
  background-color: rgb(111, 111, 111);
}

div.subMenu>span:active {
  background-color: rgb(0, 0, 0);
  /* box-shadow: inset 0px 0px 10px -2px black, inset 0px 0px 3px 0px black; */
  transform: translateY(1px);
}

div.subMenu>ul {
  list-style: none;
  margin: 0;
  padding-left: 1em;
}

div.subMenu>ul>li {
  margin-bottom: var(--list-margin);
}

div.subMenu>ul>li:first-child {
  margin-top: var(--list-margin);
}

div.subMenu>ul>li:last-child {
  margin-bottom: 0;
}

div.subMenu>ul>li>a {
  display: block;
  padding: var(--padding);
  border-radius: var(--border-radius);
  background-color: #ffffff11;
  color: white;
  text-decoration: none;
  transition: .04s;
}

div.subMenu>ul>li>a:hover {
  background-color: var(--color-1);
}

div.subMenu>ul>li>a:active {
  background-color: var(--color-2);
  /* box-shadow: inset 0px 0px 10px -2px black, inset 0px 0px 3px 0px black; */
  transform: scale(0.98);
}