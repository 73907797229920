.container {
    background-color: black;
    color: white;
    font-weight: 400;
    font-family: 'Red Hat Display', sans-serif;
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 0px 20px -2px #0000006a, 0px 0px 6px -2px rgb(0, 0, 0);
}

.btn {
    background-color: rgb(0, 82, 189);
    color: white;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    padding: .7em 1em .7em 1em;
    margin: .8em .8em 0 0;
    text-decoration: none;
    border: 0;
    border-radius: .5em;
    cursor: pointer;
    transition: .1s;
    display: inline-block;
}

.btn:hover {
    background-color: rgb(0, 110, 255);
}

.btn:active {
    background-color: rgb(0, 50, 115);
    transform: scale(.98);
}

.softHighlight {
    background-color: rgba(185, 222, 255, 0.306);
}

.block {
    padding: 1em;
    border-radius: 1em;
    box-shadow: 0px 0px 20px -2px #ffffff6a, 0px 1px 5px -2px #fff;
}

.graphics {
    background-color: rgb(34, 34, 34);
    border-radius: .2em;
    padding: .5em .4em .4em .4em ;
    margin-top: .4em;
}

.icon {
    max-height: 48px;
}

.photo {
    display: block;
    max-width: 80%;
    background-image: url('./graph.png');
    background-position: center;
    background-attachment: fixed;
    background-size: contain;
}

.section {
    overflow: hidden;
    width: 600px;
    max-width: 90vw;
    border-radius: 1em;
    padding: 1em;
    margin-top: .4em;
    margin-bottom: .7em;
    text-shadow: 0px 0px 2em white;
    box-shadow: inset 0px 0px 2em 10px black;
}

.iblock {
    display: inline-block;
    box-shadow: 0px 0px 2em 20px black;
    border-radius: 1em;
    background-color: black;
}

.table {
    text-align: left;
    border-collapse: collapse;
    width: 100%;
    margin-top: .5em;
}

.th {
    border: 1px solid white;
    background-color: #222;
}

.feature {
    border: 1px solid white;
}

.supported {
    border: 1px solid white;
    background-color: #141;
}

.unsupported {
    border: 1px solid white;
    background-color: #411;
}

.partial {
    border: 1px solid white;
    background-color: rgb(66, 68, 17);
}